window.onload = function() {
  const flashMessage = document.querySelector('.flash-messages');
  if(flashMessage) {
    if(flashMessage.classList.contains('notice')) {
      setTimeout(function() {
        flashMessage.classList.add('is-hidden');
      }, 4000);
    }
  }

  // trim polyfill : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim
  if (!String.prototype.trim) {
    (function() {
      // Make sure we trim BOM and NBSP
      var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
      String.prototype.trim = function() {
        return this.replace(rtrim, '');
      };
    })();
  }

  // missing forEach on NodeList for IE11
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }

  const inputs = document.querySelectorAll('.frg-input .frg-input__field');

  inputs.forEach(function(inputEl) {
    // in case the input is already filled..
    if(inputEl.value.trim() !== '') {
      inputEl.parentNode.classList.add('frg-input--filled');
    }

    // events:
    inputEl.addEventListener('focus', onInputFocus);
    inputEl.addEventListener('blur', onInputBlur);
  });

  function onInputFocus(ev) {
    let parent = ev.target.parentNode;
    parent.classList.add('frg-input--filled');
  }

  function onInputBlur(ev) {
    let parent = ev.target.parentNode;
    if(ev.target.value.trim() === '') {
      parent.classList.remove('frg-input--filled');
    }
  }
}
